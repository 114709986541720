var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[(!_vm.currentRoom)?_c('v-row',{staticStyle:{"max-width":"800px","margin":"auto"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.$emit('create')}}},[_vm._v("New Room")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.$emit('join')}}},[_vm._v("Join Room")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('About')],1)],1):_c('div',[(!_vm.history)?_c('div',[_c('Game',{attrs:{"screen":_vm.screen,"rows":_vm.gameState.rows,"guessInput":_vm.guessInput}}),_c('div',[_c('virtual-keyboard',{attrs:{"dialogOpen":_vm.dialogOpen,"gameState":_vm.gameState},on:{"key":_vm.handleVKeyboardPress}})],1),_c('div',[(_vm.gameState.done)?_c('GameOverCard',{attrs:{"gameState":_vm.gameState},on:{"newGame":function($event){return _vm.$emit('newGame', _vm.currentRoom)},"share":_vm.share}}):_vm._e()],1)],1):_c('div',[_c('history',{attrs:{"screen":_vm.screen,"room":_vm.currentRoom},on:{"back":function($event){return _vm.$emit('back')}}})],1)]),(_vm.liveGuesses.filter(function (x) { return this$1.now - x.lastChange < 10000; }).length)?_c('div',{staticStyle:{"position":"fixed","top":"48px","margin":"0 auto","left":"4px","z-index":"1000","max-width":"300px","border-radius":"4px","padding":"5px","background-color":"grey","opacity":"0.7"}},_vm._l((_vm.liveGuesses.filter(
        function (x) { return this$1.now - x.lastChange < 10000; }
      )),function(guess,index){return _c('v-row',{key:index,staticStyle:{"border-radius":"8px"},attrs:{"no-gutters":""}},[_c('v-col',[_c('mini-guess',{attrs:{"guessInput":guess.guessInput,"name":guess.name}})],1)],1)}),1):_vm._e(),_c('v-snackbar',{staticStyle:{"z-index":"1001"},attrs:{"app":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-snackbar',{staticStyle:{"z-index":"1001"},attrs:{"top":"","app":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" hide ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[(_vm.gameState.hardMode)?_c('div',[_c('b',{staticStyle:{"color":"red"}},[_vm._v("HARD MODE IS ON!")])]):_vm._e(),(_vm.gameState.custom)?_c('div',[_vm._v(" Custom word chosen by "),_c('b',[_vm._v(_vm._s(_vm.gameState.username))])]):_c('div',[_vm._v("This is a "),_c('b',[_vm._v("random")]),_vm._v(" word!")]),(_vm.gameState.message)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.gameState.username))]),_vm._v(" says \""+_vm._s(_vm.gameState.message)+"\" ")]):_vm._e()]),_c('v-snackbar',{staticStyle:{"z-index":"1001"},attrs:{"timeout":"2000","top":"","app":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.showConnections = false}}},'v-btn',attrs,false),[_vm._v(" hide ")])]}}]),model:{value:(_vm.showConnections),callback:function ($$v) {_vm.showConnections=$$v},expression:"showConnections"}},[_c('div',[_vm._v(" Players here: "),_vm._l((_vm.roomList),function(name,index){return _c('span',{key:index},[_c('b',[_vm._v(_vm._s(name))]),_vm._v(_vm._s(index === _vm.roomList.length - 1 ? "" : ",")+" ")])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }